import NotFound from "@garantidos/components/NotFound"

import Footer from "@mobi/ds/components/Footer"
import Header from "@mobi/ds/components/Header"

import AppLayout from "layout/AppLayout"

import use404 from "./hooks"

import { footerContactInfos } from "../Home/data"

const Page404 = () => {
  const { onGoToHome } = use404()

  return (
    <>
      <Header />
      <AppLayout title="Crédito Imobiliário Itaú | Página não encontrada">
        <NotFound onGoToHome={onGoToHome} />
        <Footer contactInfos={footerContactInfos} />
      </AppLayout>
    </>
  )
}

export default Page404
